import React, { Component } from "react"

import { withFirebase } from "../Firebase"

function getAverage(nums) {
  return nums.reduce((a, b) => a + b) / nums.length
}

class UserList extends Component {
  _initFirebase = false

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      users: [],
      tableData: [],
    }
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true

      this.setState({ loading: true })

      this.props.firebase.users().on("value", snapshot => {
        const usersObject = snapshot.val()

        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }))

        let tableData = []

        for (let i in usersList) {
          let user = usersList[i]
          let submission = user.submission
          let averages = []

          for (let i in submission) {
            let answers = []
            let questions = submission[i].questions

            for (let e in questions) {
              answers.push(questions[e].answer)
            }

            averages.push(getAverage(answers))
          }

          let tableRow = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            completed: user.completed ? "yes" : "no",
            scores: averages,
          }

          tableData.push(tableRow)
        }

        this.setState({
          users: usersList,
          loading: false,
          tableData: tableData,
        })
      })
    }
  }

  componentDidMount() {
    this.firebaseInit()
  }

  componentDidUpdate() {
    this.firebaseInit()
  }

  componentWillUnmount() {
    this.props.firebase.users().off()
  }

  render() {
    const { users, loading, tableData } = this.state

    return (
      <div>
        {loading && <div>Loading ...</div>}
        <table>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Email address</th>
              <th>Organisation</th>
              <th>Rationale</th>
              <th>Proposition</th>
              <th>Overseas Markets</th>
              <th>Resources</th>
              <th>Market/Business Model</th>
              <th>Partner Strategy</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map(user => {
              return (
                <tr key={user.uid}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.organisation ? user.organisation : ""}</td>
                  {user.completed &&
                    user.scores.map((answer, key) => {
                      return <td key={key}>{answer.toFixed(0)}</td>
                    })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default withFirebase(UserList)
