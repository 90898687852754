import React, { Fragment } from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import { withAuthorization } from "../components/Session"
import { UserList } from "../components/Users"
import * as ROLES from "../constants/roles"

const AdminPageBase = () => (
  <Fragment>
    <h1 className="heading--l">Admin</h1>
    <UserList />
  </Fragment>
)

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN]

const AdminPage = compose(withAuthorization(condition))(AdminPageBase)

export default () => (
  <Layout>
    <section className="page_admin">
      <div className="wrapper">
        <div className="row centered">
          <div className="col col-12">
            <AdminPage />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
